import React from "react";
import { Button } from "reactstrap";

const Hero = () => (
  <div className="text-center hero my-5 main-hero">
    <h1 className="mb-4">Business Accounts</h1>
    <Button type="button" color="primary" className="very-wide"><span>Get Started</span></Button>
  </div>
);

export default Hero;
