import React, { useState, useEffect } from "react";
import { Button, Alert, Nav, NavItem, TabContent, TabPane, NavLink, Col, Label, Row, Card, Badge, CardHeader, CardBody, Input, Modal, ModalHeader, ModalBody, Form, ModalFooter, Toast, ToastHeader, ToastBody } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import InputColor from 'react-input-color';

export const SuperAdmin = () => {
  const apiOrigin = process.env.REACT_APP_API
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    connections: null,
    organization: null,
    organizations: null,
    error: null,
  });

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

    const [deleteConnectionModal, setDeleteConnectionModal] = useState(false);
    const toggleDeleteConnectionModal = () => setDeleteConnectionModal(!deleteConnectionModal);
    const [deleteOrgModal, setDeleteOrgModal] = useState(false);
    const toggleDeleteOrgModal = () => setDeleteOrgModal(!deleteOrgModal);

  const openDeleteOrgModal = async (org) => {
          console.log(org);
           setDeleteOrgModal(true);
           setState({
                       ...state,
              deleteOrg: org,
           });
        }

  const openDeleteConnectionModal = async (connection) => {
          console.log(connection);
           setDeleteConnectionModal(true);
           setState({
              ...state,
              deleteConnection: connection,
           });
        }

  const [activeTab, setActiveTab] = useState('organizations');

  const [orgInfo, setOrgInfo] = useState({
    org_name: "orgname",
    org_display_name: "Org Display Name",
    org_logo_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlI-jGe1hP3KeYqFd0SzeDchGHvqnskSGjft1bQSNtEirw_Q_zg6pa2ZJ5fg&sx/auth0-logo-png-transparent.png",
    org_colour: "#eb5424",
    org_background_colour: "#44c7f4",
    org_metadata: "{\"keyone\":\"value\",\"keytwo\":\"value2\"}",
    org_owner_email: "david.weston@okta.com"
  });

  const handleChange = (event) => {
    setOrgInfo({ ...orgInfo, [event.target.name]: event.target.value });
  };

  const handleChangePrimaryColour = (event) => {
    setOrgInfo({ ...orgInfo, 'org_colour': event.hex.slice(0,7) });
  };

  const handleChangeBackgroundColour = (event) => {
    setOrgInfo({ ...orgInfo, 'org_background_colour': event.hex.slice(0,7) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addOrg(orgInfo);
    toggleModal();
    //setOrgInfo({ org_id: "org_QwPZca6z7YhBw4pU", type: "", client_id: "", client_secret: "", domain: "" });
  };

  useEffect(() => {
    (async () => {
      try {

        if (state.organizations == null || state.connections == null) {
          const organizations = await getOrgs();
          const connections = await getAllConnections();

          setState({
            ...state,
            organizations: organizations,
            connections: connections
          });

        }



      } catch (err) {
        console.log('Error occurred when fetching books');
      }
    })();
  }, []);

  const {
    getAccessTokenSilently,
    loginWithPopup,
    user,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const getOrgs = async () => {
    const token = await getAccessTokenSilently({ cacheMode: 'on' });
    try {
      const settings = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      const response = await fetch(`${apiOrigin}/orgs`, settings);
      console.log(response);
      const responseData = await response.json();

      if (responseData.organizations) {
        console.log(responseData.members);

        setState({
          ...state,
          organizations: responseData.organizations
        });
        return responseData.organizations;
      } else {
        console.log("in error");
      }
    } catch (error) {
      console.log("in error");
      setState({
        ...state,
        error: error.error,
      });
    }
  };


  const deleteConnection = async (connection) => {
      var data = { connection_id: connection };
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'on' });
        const settings = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        };

        const response = await fetch(`${apiOrigin}/connection/delete`, settings);
        console.log(response);
        getAllConnections();

      } catch (error) {
        console.log("in error " + error);
      }

      toggleDeleteConnectionModal();

  };


  const deleteOrganization = async (organization) => {
      var data = { org_id: organization };
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'on' });
        const settings = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        };

        const response = await fetch(`${apiOrigin}/org/delete`, settings);
        console.log(response);
        getOrgs();
        toggleDeleteOrgModal();

      } catch (error) {
        console.log("in error " + error);
      }

  };


  const getAllConnections = async () => {
    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' });
      const settings = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      const response = await fetch(`${apiOrigin}/connections`, settings);
      console.log(response);
      const responseData = await response.json();

      if (responseData.connections) {

        setState({
          ...state,
          connections: responseData.connections
        });
        return responseData.connections;
      } else {
        console.log("in error");
      }
    } catch (error) {
      console.log("in error");
      setState({
        ...state,
        error: error.error,
      });
    }
  };



  const addOrg = async () => {

    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' });
      setState({
        ...state,
        showResult: false,
        apiMessage: "",
      });


      const settings = {
        method: 'POST',
        body: JSON.stringify(orgInfo),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      const response = await fetch(`${apiOrigin}/org/create`, settings);
      console.log(response);
      //const data = await fetchResponse.json();
      const responseData = await response.json();

      if (responseData.organization) {

        setState({
          ...state,
          showResult: true,
          apiMessage: responseData.msg,
          error: "",
        });
        getOrgs();
      } else {
        console.log("in error");
        setState({
          ...state,
          showResult: true,
          apiMessage: "",
          error: responseData.msg,
        });
      }
    } catch (error) {
      console.log("in error");
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      {state.error === "consent_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleConsent)}
          >
            consent to get access to users api
          </a>
        </Alert>
      )}

      {state.error === "login_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            log in again
          </a>
        </Alert>
      )}
      <Nav tabs>
        <NavItem className="clickable">
          <NavLink active={activeTab == 'organizations'} onClick={() => setActiveTab('organizations')}>Organizations</NavLink>
        </NavItem>
        <NavItem className="clickable">
          <NavLink active={activeTab == 'connections'} onClick={() => setActiveTab('connections')}>Connections</NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="organizations">

         <Modal isOpen={deleteOrgModal} toggle={toggleDeleteOrgModal}>

               <ModalHeader toggle={toggleDeleteOrgModal}>Delete Organization?</ModalHeader>
            {state.deleteOrg != null && (
               <ModalBody>

                <p>Are you sure you want to delete the Organization : {state.deleteOrg.display_name}</p>
              </ModalBody>
              )}
            {state.deleteOrg != null && (
              <ModalFooter>

                    <Button color="primary" id="connection" onClick={() => deleteOrganization(state.deleteOrg.id)}>
                      Delete Organization
                    </Button>

                <Button color="secondary" onClick={toggleDeleteOrgModal}>
                  Cancel
                </Button>
              </ModalFooter>
              )}

            </Modal>

          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Add Organization</ModalHeader>
            <ModalBody>
              <Form>
                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Organization Name</Label>
                  </Col>
                  <Col md>
                    <Input name="org_name" value={orgInfo.org_name} onChange={handleChange} />
                  </Col>
                </Row>

                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Display Name</Label>
                  </Col>
                  <Col md>
                    <Input name="org_display_name" value={orgInfo.org_display_name} onChange={handleChange} />
                  </Col>
                </Row>

                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Logo URL</Label>
                  </Col>
                  <Col md>
                    <Input name="org_logo_url" value={orgInfo.org_logo_url} onChange={handleChange} />
                  </Col>
                </Row>

                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Primary Colour</Label>
                  </Col>
                  <Col md>
                    <InputColor initialValue={orgInfo.org_colour} onChange={handleChangePrimaryColour} placement="right" />
                  </Col>
                </Row>

                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Background Colour</Label>
                  </Col>
                  <Col md>
                    <InputColor initialValue={orgInfo.org_background_colour} onChange={handleChangeBackgroundColour} placement="right" />
                  </Col>
                </Row>

                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Metadata</Label>
                  </Col>
                  <Col md>
                    <Input name="org_metadata" value={orgInfo.org_metadata} onChange={handleChange} />
                  </Col>
                </Row>

                <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                  <Col md={4}>
                    <Label>Owner e-Mail</Label>
                  </Col>
                  <Col md>
                    <Input name="org_owner_email" value={orgInfo.org_owner_email} onChange={handleChange} />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleSubmit}>
                Add Organization
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
            <Col md></Col>
            <Col md={3}>
              <Button color="primary" onClick={toggleModal} className="float-right margin-2-top margin-2-bottom"> + Add Organization</Button>
            </Col>
          </Row>

          <Modal isOpen={state.showResult} toggle={() => { setState({ ...state, showResult: false }) }}>
            <ModalHeader toggle={() => { setState({ ...state, showResult: false }) }}>Info</ModalHeader>
            <ModalBody>
              {state.apiMessage && (
                <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
              )}

              {state.error && (
                <span>{JSON.stringify(state.error, null, 2)}</span>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => { setState({ ...state, showResult: false }) }}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          {state.organizations != null && state.organizations.map((organization, index) => {
            return (
              <Card className="margin-2-top margin-2-bottom">
                <CardHeader>
                  <span className="font-weight-bold">{organization.name}</span>
                </CardHeader>
                <CardBody>
                                   {organization.branding && organization.branding.logo_url && (
                                    <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                                      <Col md={2}>
                                        <Label></Label>
                                        <img src={organization.branding.logo_url} style={{ maxWidth: '100%' }} />
                                      </Col>
                                      <Col md>

                                      </Col>
                                    </Row>
                                    )}
                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>Name</Label>
                    </Col>
                    <Col md>
                      <Input value={organization.name} readOnly />
                    </Col>
                  </Row>
                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>Organization ID</Label>
                    </Col>
                    <Col md>
                      <Input value={organization.id} readOnly />
                    </Col>
                  </Row>


                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>Display Name</Label>
                    </Col>
                    <Col md>
                      <Input value={organization.display_name} readOnly />
                    </Col>
                  </Row>
                  {organization.id != user.org_id && (
                    <Button value={organization.id} color="danger" className="float-right margin-2-top margin-2-bottom" onClick={() => openDeleteOrgModal(organization)}>Delete Organization</Button>
                  )}
                  {organization.id == user.org_id && (
                    <Badge color="success" pill>Active Organization</Badge>
                  )}
                </CardBody>
              </Card>
            );
          })}
        </TabPane>
        <TabPane tabId="connections">
          {state.connections != null && state.connections.map((connection, index) => {
            return (

              <Card className="margin-2-top margin-2-bottom">
                <CardHeader>
                  <span className="font-weight-bold">{connection.name}</span>
                </CardHeader>
                <CardBody>
                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>Strategy</Label>
                    </Col>
                    <Col md>
                      <Input value={connection.strategy} readOnly />
                    </Col>
                  </Row>
                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>Connection ID</Label>
                    </Col>
                    <Col md>
                      <Input value={connection.id} readOnly />
                    </Col>
                  </Row>
                  {connection.allow_delete == true && (
                    <Button value={connection.id} color="danger" className="float-right margin-2-top margin-2-bottom" onClick={() => openDeleteConnectionModal(connection)}>Delete Connection</Button>
                  )}
                  {connection.allow_delete == false && (
                    <Badge color="success" pill>Active Connection</Badge>
                  )}
                </CardBody>
              </Card>
            );
          })}
        </TabPane>
      </TabContent>
                                         <Modal isOpen={deleteConnectionModal} toggle={toggleDeleteConnectionModal}>

                                               <ModalHeader toggle={toggleDeleteConnectionModal}>Delete Connection?</ModalHeader>
                                            {state.deleteConnection != null && (
                                               <ModalBody>

                                                <p>Are you sure you want to delete the Connection : {state.deleteConnection.name}</p>
                                              </ModalBody>
                                              )}
                                            {state.deleteConnection != null && (
                                              <ModalFooter>

                                                    <Button color="primary" id="connection" onClick={() => deleteConnection(state.deleteConnection.id)}>
                                                      Delete Connection
                                                    </Button>

                                                <Button color="secondary" onClick={toggleDeleteConnectionModal}>
                                                  Cancel
                                                </Button>
                                              </ModalFooter>
                                              )}

                                            </Modal>

    </>
  );
};



export default withAuthenticationRequired(SuperAdmin, {
  onRedirecting: () => <Loading />,
});
