import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const apiOrigin = process.env.REACT_APP_API;

  const getOrgByName = async (orgName) => {
    try {
      const settings = {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              }
          };

          const response = await fetch(`${apiOrigin}/org?org_name=${orgName}`, settings);
          const responseData = await response.json();
          return responseData.org_id;


      } catch (error) {
          console.log("in error : " + error);
          return "";
      }
  };

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider



var providerConfig;


providerConfig = {
  domain: process.env.REACT_APP_CUSTOM_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
  redirectUri: window.location.origin,
//        useRefreshTokens: true,
        scope: 'create:sso',
//        cacheLocation: "localstorage",
  onRedirectCallback,
};


ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();