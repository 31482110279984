import React, {useState} from "react";
import { Container, Row, Col, Form, Label, Input, Collapse, Button } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ProfileComponent = () => {
  const { user } = useAuth0();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Form>
        <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
          <Col md={2}>
            <Label>Nickname</Label>
          </Col>
          <Col md>
            <Input value={user.nickname} readOnly />
          </Col>
        </Row>
        <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
          <Col md={2}>
            <Label>e-Mail</Label>
          </Col>
          <Col md>
            <Input value={user.email} readOnly />
          </Col>
        </Row>
        <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
          <Col md={2}>
            <Label>Organization</Label>
          </Col>
          <Col md>
            <Input value={user.org_id} readOnly />
          </Col>
        </Row>
      </Form>

      <Button color="link" onClick={toggle}> {isOpen?'Show less...':'Show more...'} </Button>
      <Collapse isOpen={isOpen}>
        <Row>
          <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        </Row>
      </Collapse>
    </Container>

  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
