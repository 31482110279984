import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import contentData from "../utils/contentData";

class Content extends Component {
  render() {
    return (
      <div>
        <h2 className="my-5 text-center">ONE PLACE FOR ALL OF YOUR TRANSPORT AND DELIVERY NEEDS </h2>
        <Row className="row-cols-lg-auto g-3 align-items-center">
          <Col md={6}>
            <h3>BUILT FOR YOUR BUSINESS</h3>
              <p className="paragraph">We wholeheartedly believe the combination of our technology, people and fleet, affords our customers a best in class 
                managed programme. By designing a tailored solution around the exact needs of your business (including private hire, 
                black taxi, courier and delivery services, support and analytics) we take the complexity out of your operations, 
                making business transport trouble-free, carbon-neutral and cost-efficient – for every journey, every day, for everyone 
                on your team.</p>
              <p>
                <a class="cta" href="https://www.addisonlee.com/business/apply/?">Open an account</a>
              </p>
              <p>
                <a class="link-caps" href="https://www.addisonlee.com/support/">Help and support</a>
              </p>
          </Col>
          <Col md>
            <img src="https://www.addisonlee.com/wp-content/uploads/2021/12/Glude-Ad-Lee-Stills-136.jpg" className="car-image"></img>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Content;
