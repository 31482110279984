import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  Label,
  Input,
  NavItem,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  Badge,
  Spinner
} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import InputColor from 'react-input-color';

export const OrgAdmin = () => {
  const apiOrigin = process.env.REACT_APP_API
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    connection: null,
    deleteConnection: null,
    orgConnections: null,
    branding: null,
    members: null,
    error: null,
  });

  const [addIdpModal, setAddIdpModal] = useState(false);
  const toggleAddIdpModal = () => setAddIdpModal(!addIdpModal);
  const [disableIdpModal, setDisableIdpModal] = useState(false);
  const toggleDisableIdpModal = () => setDisableIdpModal(!disableIdpModal);
  const [removeRoleModal, setRemoveRoleModal] = useState(false);
  const toggleRemoveRoleModal = () => setRemoveRoleModal(!removeRoleModal);
  const [addRoleModal, setAddRoleModal] = useState(false);
  const toggleAddRoleModal = () => setAddRoleModal(!addRoleModal);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const toggleAddMemberModal = () => setAddMemberModal(!addMemberModal);

const openDisableIdpModal = async (connection) => {
        console.log(connection);
         setDisableIdpModal(true);
         setState({
                     ...state,
            deleteConnection: connection,
         });
      }

const openRemoveRoleModal = async (member) => {
        console.log(member);
         setRemoveRoleModal(true);
         setState({
                     ...state,
            deleteMember: member,
         });
      }

      const openAddRoleModal = async (member) => {
              console.log(member);
               setAddRoleModal(true);
               setState({
                           ...state,
                  deleteMember: member,
               });
            }

  const [activeTab, setActiveTab] = useState('idp');

  const [idpInfo, setIdpInfo] = useState({
    type: "waad",
    name: "",
    client_id: "",
    adfs_url: "",
    domain: "",
    signing_certificate_content: "",
    ext_groups: false,
    ext_profile: false,
    ext_nested_groups: false,
    useCommonEndpoint: false,
    client_secret: "",
    signin_url: "",
    digestAlgorithm: "sha_256",
    signatureAlgorithm: "rsa_sha_256"
  });

  const [orgInfo, setOrgInfo] = useState({
    org_display_name: "",
    org_logo_url: "",
    org_colour: "",
    org_background_colour: "",
    org_metadata: "",
  });

  const [inviteEmail, setInviteEmail] = useState('');
  const setEmail = (event) => { setInviteEmail(event.target.value) }

  const handleChange = (event) => {
    console.log(event);
    console.log(event.target.name);
    console.log(event.target.value);

    var keyName = event.target.name;
    console.log(keyName);
    console.log(idpInfo[keyName]);

    if(event.target.value == "on" || event.target.value == "off" ) {
        setIdpInfo({ ...idpInfo, [event.target.name]: !idpInfo[keyName] });
    }  else if (event.target.value.includes('.pem') && event.target.files[0]!=null) {
        console.log("here");

        const reader = new FileReader()
            reader.onload = async (event) => {
              const text = (event.target.result)
              console.log(text);
              setIdpInfo({ ...idpInfo, "signing_certificate_content": text });
            };
            reader.readAsText(event.target.files[0])
    } else {
        setIdpInfo({ ...idpInfo, [event.target.name]: event.target.value });
        }

  };

  const handleChangeBrand = (event) => {
    setOrgInfo({ ...orgInfo, [event.target.name]: event.target.value });
  };

  const handleChangePrimaryColour = (event) => {
    setOrgInfo({ ...orgInfo, 'org_colour': event.hex.slice(0, 7) });
  };

  const handleChangeBackgroundColour = (event) => {
    setOrgInfo({ ...orgInfo, 'org_background_colour': event.hex.slice(0, 7) });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.id == 'connection') {
      addIdp(idpInfo);
      toggleAddIdpModal();
    } else if (event.target.id == 'branding') {
      updateOrg();
    } else if (event.target.id == 'member') {
      inviteMember();
      toggleAddMemberModal();
    }
    //setIdpInfo({ org_id: "org_QwPZca6z7YhBw4pU", type: "", client_id: "", client_secret: "", domain: "" });
  };

  var loading = true;

  useEffect(() => {
    (async () => {
      try {

        if (state.members == null || state.orgConnections == null) {
          const members = await getOrgMembers();
          const connections = await getOrgConnections();

          setState({
            ...state,
            members: members,
            orgConnections: connections
          });

        }

        if (orgInfo.org_display_name == "") {
          const org = await getOrg();
        }


      } catch (err) {
        console.log('Error occurred when fetching books');
      }
    })();
  }, []);


  const {
    getAccessTokenSilently,
    loginWithPopup,
    user,
    loginWithRedirect,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const getOrgMembers = async () => {
        const token = await getAccessTokenSilently({cacheMode: 'on'});
        try {
          const settings = {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`
                  }
              };

      const response = await fetch(`${apiOrigin}/org/members`, settings);
      const responseData = await response.json();

      if (responseData.members) {


              setState({
                ...state,
                members: responseData.members
                      });
                  return responseData.members;
                } else {
                    console.log("in error");
                }
          } catch (error) {
              console.log("in error : " + error);
              setState({
                ...state,
                error: error.error,
              });
          }
      };


  const getOrg = async () => {
    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' });
      const settings = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      const response = await fetch(`${apiOrigin}/org/branding`, settings);
      const responseData = await response.json();


      setOrgInfo({ ...orgInfo, 'org_display_name': responseData.org.display_name, 'org_metadata': JSON.stringify(responseData.org.metadata), 'org_background_colour': responseData.org.branding.colors.page_background, 'org_colour': responseData.org.branding.colors.primary, 'org_logo_url': responseData.org.branding.logo_url });

    } catch (error) {
      console.log("in error", error);
    }
  };

  const updateOrg = async () => {
    try {
      var data = {
        org_display_name: orgInfo.org_display_name,
        org_logo_url: orgInfo.org_logo_url,
        org_colour: orgInfo.org_colour.substring(0, 7),
        org_background_colour: orgInfo.org_background_colour.substring(0, 7),
        org_metadata: JSON.parse(orgInfo.org_metadata)
      };

      const token = await getAccessTokenSilently({ cacheMode: 'on' });
      const settings = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
      };

            const response = await fetch(`${apiOrigin}/org/branding`, settings);

        } catch (error) {
            console.log("in error : " + error);
        }
    };

  const disableOrgConnection = async (connection) => {


      var data = { connection_id: connection };
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'on' });
        const settings = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        };

        const response = await fetch(`${apiOrigin}/connection/disable`, settings);
        const connections = await getOrgConnections();
        toggleDisableIdpModal();

      } catch (error) {
        console.log("in error " + error);
      }


  };

      const makeAdmin = async (user_id) => {

        var data = { user_id: user_id };
        try {
        const token = await getAccessTokenSilently({cacheMode: 'on'});
          const settings = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
              },
              body: JSON.stringify(data)
          };

          const response = await fetch(`${apiOrigin}/org/member/role`, settings);
          const members = await getOrgMembers();

          } catch (error) {
              console.log("in error " + error);
          }

          toggleAddRoleModal();

      };

    const removeAdmin = async (user_id) => {


      var data = { user_id: user_id };
      try {
      const token = await getAccessTokenSilently({cacheMode: 'on'});
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(`${apiOrigin}/org/member/role/delete`, settings);
        const members = await getOrgMembers();

        } catch (error) {
            console.log("in error " + error);
        }
        toggleRemoveRoleModal();

    };

  const getOrgConnections = async () => {
    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' });

      const settings = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      const response = await fetch(`${apiOrigin}/org/connections`, settings);
      const responseData = await response.json();

      if (responseData.connections) {

        setState({
          ...state,
          orgConnections: responseData.connections
        });
        return responseData.connections;
      } else {
        console.log("in error");
      }
    } catch (error) {
      console.log("in error");
      setState({
        ...state,
        error: error.error,
      });
    }

  };
  const Checkbox = ({ label, name, value, onChange }) => {
    return (
      <label>
        <input type="checkbox" name={name} checked={value} onChange={onChange} />
      </label>
    );
  };

    const loginWithIdp = async () => {
        console.log('here');
        const settings = {
                method: 'GET',
                params: {

                  client_id:process.env.REACT_APP_CLIENT_ID,
                  returnTo:'http://localhost:3000/admin?makeadmin=true'
                }
            };

    const response = await fetch(`${process.env.REACT_APP_CUSTOM_DOMAIN}/v2/logout`, settings);
    console.log(window.location.pathname);
    loginWithRedirect({organization: user.org_id, appState: {returnTo: window.location.pathname }, connection: state.connection.name, ['ext-createIdp']: true});

  };

  const addIdp = async () => {

    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' });
      setState({
        ...state,
        showResult: false,
        apiMessage: "",
      });

      const settings = {
        method: 'POST',
        body: JSON.stringify(idpInfo),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      console.log('Checkpoint')
      const response = await fetch(`${apiOrigin}/connection/create`, settings);
      const responseData = await response.json();
      console.log(responseData);

                if (responseData.msg.includes('successfully')) {
                console.log("in success")
                const connections = await getOrgConnections();
                setState({
                  ...state,
                  showResult: true,
                  apiMessage: responseData,
                  error: "",
                  connection: responseData.connection,
                        });
                        //
                  } else {
                      console.log("in error : ");
                      setState({
                        ...state,
                          showResult: true,
                        error: responseData,
                        apiMessage: ""
                      });
                  }
            } catch (error) {
                console.log("in error : " + error);

      setState({
        ...state,
        error: error.error,
        apiMessage: ""
      });
    }
  };

  const inviteMember = async () => {

    try {
      const token = await getAccessTokenSilently({ cacheMode: 'on' });
      var data = { invitee: inviteEmail}
      setState({
        ...state,
        showResult: false,
        apiMessage: "",
      });

      const settings = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };
      console.log('Checkpoint')
      const response = await fetch(`${apiOrigin}/member/invite`, settings);
      const responseData = await response.json();

      if (responseData.msg.includes('successfully')) {
        const connections = await getOrgConnections();
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
          error: "",
        });
        //
      } else {
        console.log("in error");
        setState({
          ...state,
          showResult: true,
          error: responseData,
          apiMessage: ""
        });
      }
    } catch (error) {
      console.log("in error : " + error);

      setState({
        ...state,
        error: error.error,
        apiMessage: ""
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      {state.error === "consent_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleConsent)}
          >
            consent to get access to users api
          </a>
        </Alert>
      )}

      {state.error === "login_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            log in again
          </a>
        </Alert>
      )}
      <Nav tabs>
         <NavItem className="clickable">
           <NavLink active={activeTab == 'idp'} onClick={() => setActiveTab('idp')}>Identity Providers</NavLink>
         </NavItem>
         <NavItem className="clickable">
           <NavLink active={activeTab == 'members'} onClick={() => setActiveTab('members')}>Members</NavLink>
         </NavItem>
         <NavItem className="clickable">
           <NavLink active={activeTab == 'branding'} onClick={() => setActiveTab('branding')}>Branding</NavLink>
         </NavItem>
       </Nav>
       <TabContent activeTab={activeTab}>
         <TabPane tabId="idp">
           <Container>
             <Modal isOpen={disableIdpModal} toggle={toggleDisableIdpModal}>

                   <ModalHeader toggle={toggleDisableIdpModal}>Remove Connection?</ModalHeader>
                {state.deleteConnection != null && (
                   <ModalBody>

                    <p>Are you sure you want to remove the connection : {state.deleteConnection.connection.name} ({state.deleteConnection.connection.strategy})</p>
                  </ModalBody>
                  )}
                {state.deleteConnection != null && (
                  <ModalFooter>

                        <Button color="primary" id="connection" onClick={() => disableOrgConnection(state.deleteConnection.connection_id)}>
                          Remove Connection
                        </Button>

                    <Button color="secondary" onClick={toggleDisableIdpModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                  )}

                </Modal>


             <Modal isOpen={addIdpModal} toggle={toggleAddIdpModal}>
               <ModalHeader toggle={toggleAddIdpModal}>Add your Enterprise IDP</ModalHeader>
               <ModalBody>
                 <Form>

                   <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                     <Col md={4}>
                       <Label>Connection Type</Label>
                     </Col>
                     <Col md>
                              <input
                                 type="radio"
                                 value="okta"
                                 name="type"
                                 checked={idpInfo.type === "okta"}
                                 onChange={handleChange}
                               />
                               <img src="https://cdn.auth0.com/manhattan/versions/1.4025.0/assets/logos/okta/okta-avatar.svg" style={{ maxHeight: '10%', maxWidth: '15%' }} />
                                Okta Workforce
                              <br></br> <input
                                 type="radio"
                                 name="type"
                                 value="waad"
                                 checked={idpInfo.type === "waad"}
                                 onChange={handleChange}
                               />
                               <img src="https://cdn.auth0.com/manhattan/versions/1.4025.0/assets/logos/azure-ad/azure-ad-avatar.svg" style={{ maxWidth: '100%' }} />
                               Azure AD

                              <br></br> <input
                                 type="radio"
                                 name="type"
                                 value="ping"
                                 checked={idpInfo.type === "ping"}
                                 onChange={handleChange}
                               />
                               <img src="https://cdn.auth0.com/manhattan/versions/1.4025.0/assets/logos/ping-federate/ping-federate-avatar.svg" style={{ maxWidth: '100%' }} />
                               Ping Federate
                              <br></br><input
                                type="radio"
                                name="type"
                                value="google"
                                checked={idpInfo.type === "google"}
                                onChange={handleChange}
                              />
                              <img src="https://cdn.auth0.com/manhattan/versions/1.4025.0/assets/logos/g-suite/g-suite-avatar.svg" style={{ maxWidth: '100%' }} />
                              Google Workspace
                              <br></br> <input
                                 type="radio"
                                 name="type"
                                 value="adfs"
                                 checked={idpInfo.type === "adfs"}
                                 onChange={handleChange}
                               />
                               <img src="https://cdn.auth0.com/manhattan/versions/1.4025.0/assets/logos/adfs/adfs-avatar.svg" style={{ maxWidth: '100%' }} />
                               ADFS
                              <br></br> <input
                                 type="radio"
                                 name="type"
                                 value="oidc"
                                 checked={idpInfo.type === "oidc"}
                                 onChange={handleChange}
                               />
                               <img src="http://openid.net/logo-graphics/openid-icon-500x500.png" style={{ maxWidth: '15%' }} />
                               Custom OIDC
                               <br></br><input
                                 type="radio"
                                 value="samlp"
                                 name="type"
                                 checked={idpInfo.type === "samlp"}
                                 onChange={handleChange}
                               />
                               <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzXjlxfxo_cXIRPuUV95OqExCrVWWPIp94ZCUQTMAPrqpq_0KRJwJh1Xp5yTMNxez8ilQ&usqp=CAU" style={{ maxWidth: '15%' }} />
                               Custom SAML
                               <br></br>
                     </Col>
                   </Row>

                   <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                     <Col md={4}>
                       <Label>Connection Name</Label>
                     </Col>
                     <Col md>
                       <Input name="name" value={idpInfo.name} onChange={handleChange} />
                     </Col>
                   </Row>
                   {idpInfo.type != 'ping' && idpInfo.type != 'samlp' && idpInfo.type != 'adfs' && (
                     <span>

                        <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                          <Col md={4}>
                            <Label>Domain</Label>
                          </Col>
                          <Col md>
                            <Input name="domain" value={idpInfo.domain} onChange={handleChange} />
                          </Col>
                        </Row>
                       <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                         <Col md={4}>
                           <Label>Client ID</Label>
                         </Col>
                         <Col md>
                           <Input name="client_id" value={idpInfo.client_id} onChange={handleChange} />
                         </Col>
                       </Row>

                     </span>
                   )}

                   {idpInfo.type != 'ping' && idpInfo.type != 'adfs' && idpInfo.type != 'oidc' && idpInfo.type != 'samlp' && (
                     <span>

                       <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                         <Col md={4}>
                           <Label>Client Secret</Label>
                         </Col>
                         <Col md>
                           <Input name="client_secret" value={idpInfo.client_secret} onChange={handleChange} />
                         </Col>
                       </Row>


                     </span>
                   )}

                   {idpInfo.type == 'adfs' && (
                     <span>

                       <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                         <Col md={4}>
                           <Label>ADFS Url</Label>
                         </Col>
                         <Col md>
                           <Input name="adfs_url" value={idpInfo.adfs_url} onChange={handleChange} />
                         </Col>
                       </Row>


                     </span>
                   )}

                  {(idpInfo.type == 'samlp') && (
                    <span>

                       <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                         <Col md={4}>
                           <Label>Signin Url</Label>
                         </Col>
                         <Col md>
                           <Input name="signin_url" value={idpInfo.signin_url} onChange={handleChange} />
                         </Col>
                       </Row>


                    </span>
                  )}

                  {(idpInfo.type == 'samlp' || idpInfo.type == 'ping') && (
                    <span>

                      <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                        <Col md={4}>
                          <Label>X509 Signing Certificate</Label>
                        </Col>
                        <Col md>
                          <Input type="file" name="signing_certificate" value={idpInfo.signing_certificate} onChange={handleChange} />
                        </Col>
                      </Row>


                                             <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                                               <Col md={4}>
                                                 <Label>Sign Request Algorithm</Label>
                                               </Col>
                                               <Col md>
                                                 <Input type="select" name="signatureAlgorithm" value={idpInfo.signatureAlgorithm} onChange={handleChange}>
                                                   <option value="rsa_sha_256">RSA-SHA256</option>
                                                   <option value="rsa_sha_1">RSA-SHA1</option>
                                                 </Input>
                                               </Col>
                                             </Row>
                                             <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                                               <Col md={4}>
                                                 <Label>Sign Request Algorithm Digest</Label>
                                               </Col>
                                               <Col md>
                                                 <Input type="select" name="digestAlgorithm" value={idpInfo.digestAlgorithm} onChange={handleChange}>
                                                   <option value="sha_256">SHA256</option>
                                                   <option value="sha_1">SHA1</option>
                                                 </Input>
                                               </Col>
                                             </Row>


                    </span>
                  )}



                        {idpInfo.type == 'waad' && (
                               <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                                 <Col md={4}>
                                   <Label>Use common endpoint</Label>
                                 </Col>
                                 <Col>
                                       <Checkbox
                                         label=""
                                         name="useCommonEndpoint"
                                         value={idpInfo.useCommonEndpoint}
                                         onChange={handleChange}
                                       />
                                 </Col>
                               </Row>
                        )}

                     {idpInfo.type == 'waad' && (
                            <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                              <Col md={4}>
                                <Label>Extended Profile</Label>
                              </Col>
                              <Col>
                                                                       <Checkbox
                                                                         label=""
                                                                         name="ext_profile"
                                                                         value={idpInfo.ext_profile}
                                                                         onChange={handleChange}
                                                                       />
                              </Col>
                            </Row>
                     )}

                   {idpInfo.type == 'waad' && (
                          <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                            <Col md={4}>
                              <Label>Get user groups</Label>
                            </Col>
                            <Col>
                                                                     <Checkbox
                                                                       label=""
                                                                       name="ext_groups"
                                                                       value={idpInfo.ext_groups}
                                                                       onChange={handleChange}
                                                                     />
                            </Col>
                          </Row>
                   )}

                        {idpInfo.type == 'waad' && (
                               <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                                 <Col md={4}>
                                   <Label>Include nested groups</Label>
                                 </Col>
                                 <Col>
                                                                          <Checkbox
                                                                            label=""
                                                                            name="ext_nested_groups"
                                                                            value={idpInfo.ext_nested_groups}
                                                                            onChange={handleChange}
                                                                          />
                                 </Col>
                               </Row>
                        )}



                   {idpInfo.type == 'ping' && (
                     <span>
                       <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                         <Col md={4}>
                           <Label>Ping Federate Server URL</Label>
                         </Col>
                         <Col md>
                           <Input name="server_url" value={idpInfo.server_url} onChange={handleChange} />
                         </Col>
                       </Row>

                     </span>
                   )}
                 </Form>
               </ModalBody>
               <ModalFooter>
                 <Button color="primary" id="connection" onClick={handleSubmit}>
                   Add IDP
                 </Button>{' '}
                 <Button color="secondary" onClick={toggleAddIdpModal}>
                   Cancel
                 </Button>
               </ModalFooter>
             </Modal>
             <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
               <Col md></Col>
               <Col md={3}>
                 <Button color="primary" onClick={toggleAddIdpModal} className="float-right margin-2-top margin-2-bottom"> + Add new Enterprise IDP</Button>
               </Col>
             </Row>
             {state.orgConnections != null && state.orgConnections.map((connection, index) => {
               return (
                 <Card className="margin-2-top margin-2-bottom">
                   <CardHeader>
                     <span className="font-weight-bold">{connection.connection.name}</span>
                   </CardHeader>
                   <CardBody>
                     <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                       <Col md={2}>
                         <Label>Strategy</Label>
                       </Col>
                       <Col md>
                         <Input value={connection.connection.strategy} readOnly />
                       </Col>
                     </Row>
                     <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                       <Col md={2}>
                         <Label>Connection ID</Label>
                       </Col>
                       <Col md>
                         <Input value={connection.connection_id} readOnly />
                       </Col>
                     </Row>
                     {connection.allow_delete == true && (
                       <Button value={connection.connection_id} onClick={() => openDisableIdpModal(connection)}>Remove Connection</Button>
                     )}
                     {connection.allow_delete == false && (
                       <Badge color="success" pill>Active Connection</Badge>
                     )}
                   </CardBody>
                 </Card>
               );
             })}
           </Container>
         </TabPane>
         <TabPane tabId="members">
              <Modal isOpen={removeRoleModal} toggle={toggleRemoveRoleModal}>

                    <ModalHeader toggle={toggleRemoveRoleModal}>Remove Role?</ModalHeader>
                 {state.deleteMember != null && (
                    <ModalBody>

                     <p>Are you sure you want to remove the Admin Role from : {state.deleteMember.user_id}</p>
                   </ModalBody>
                   )}
                 {state.deleteMember != null && (
                   <ModalFooter>

                         <Button color="primary" id="connection" onClick={() => removeAdmin(state.deleteMember.user_id)}>
                           Remove Role
                         </Button>

                     <Button color="secondary" onClick={toggleRemoveRoleModal}>
                       Cancel
                     </Button>
                   </ModalFooter>
                   )}

                 </Modal>

              <Modal isOpen={addRoleModal} toggle={toggleAddRoleModal}>

                    <ModalHeader toggle={toggleAddRoleModal}>Add Role?</ModalHeader>
                 {state.deleteMember != null && (
                    <ModalBody>

                     <p>Are you sure you want to add the Admin Role from : {state.deleteMember.user_id}</p>
                   </ModalBody>
                   )}
                 {state.deleteMember != null && (
                   <ModalFooter>

                         <Button color="primary" id="connection" onClick={() => makeAdmin(state.deleteMember.user_id)}>
                           Add Role
                         </Button>

                     <Button color="secondary" onClick={toggleAddRoleModal}>
                       Cancel
                     </Button>
                   </ModalFooter>
                   )}

                 </Modal>

           <Modal isOpen={addMemberModal} toggle={toggleAddMemberModal}>
             <ModalHeader toggle={toggleAddMemberModal}>Invite a member</ModalHeader>
             <ModalBody>
               <Form>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Col md>
                     <Input type="text" name="email" placeholder="name@example.org" value={inviteEmail} onChange={setEmail} />
                   </Col>
                 </Row>
               </Form>
             </ModalBody>
             <ModalFooter>
               <Button color="primary" id="member" onClick={handleSubmit}>
                 Invite Member
               </Button>{' '}
               <Button color="secondary" onClick={toggleAddMemberModal}>
                 Cancel
               </Button>
             </ModalFooter>
           </Modal>
           <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
             <Col md></Col>
             <Col md={3}>
               <Button color="primary" onClick={toggleAddMemberModal} className="float-right margin-2-top margin-2-bottom"> + Add new Member</Button>
             </Col>
           </Row>
           {state.members != null && state.members.map((members, index) => {
             return (
               <Card className="margin-2-top margin-2-bottom">
                 <CardHeader>
                   <span className="font-weight-bold">{members.user_id}</span>
                 </CardHeader>
                 <CardBody>
                   <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                     <Col md={2}>
                       <Label>e-mail</Label>
                     </Col>
                     <Col md>
                       <Input value={members.email} readOnly />
                     </Col>
                   </Row>
                   <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                     <Col md={2}>
                       <Label>Last Login</Label>
                     </Col>
                     <Col md>
                       <Input value={members.details.last_login} readOnly />
                     </Col>
                   </Row>
                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>Logins</Label>
                    </Col>
                    <Col md>
                      <Input value={members.details.logins_count} readOnly />
                    </Col>
                  </Row>
                    <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                      <Col md={2}>
                        <Label>User metadata</Label>
                      </Col>
                      <Col md>
                        <Input value={JSON.stringify(members.details.user_metadata)} readOnly />
                      </Col>
                    </Row>
                  <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                    <Col md={2}>
                      <Label>App metadata</Label>
                    </Col>
                    <Col md>
                      <Input value={JSON.stringify(members.details.app_metadata)} readOnly />
                    </Col>
                  </Row>
                   <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                     <Col md={2}>
                       <Label>Roles</Label>
                     </Col>
                     <Col md>
                         { members.roles.length > 0 && (
                             <Input value={members.roles[0] ? members.roles[0].name : ''} readOnly />
                         )}
                     </Col>

                   </Row>


                   { members.roles.length > 0 &&  (
                       <Button value={members.user_id} onClick={() => openRemoveRoleModal(members)}>Remove Admin</Button>
                   )}
                   { members.roles.length == 0 && (
                       <Button value={members.user_id} onClick={() => openAddRoleModal(members)}>Make Admin</Button>
                   )}
                 </CardBody>
               </Card>
             );
           })}

         </TabPane>
         <TabPane tabId="branding">
           <Container>
             <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom" style={{ minHeight: '2em' }}></Row>
             <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
               <Col md={8}>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Col md={4}>
                     <Label>Display Name</Label>
                   </Col>
                   <Col md>
                     <Input name="org_display_name" value={orgInfo.org_display_name} onChange={handleChangeBrand} />
                   </Col>
                 </Row>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Col md={4}>
                     <Label>Logo URL</Label>
                   </Col>
                   <Col md>
                     <Input name="org_logo_url" value={orgInfo.org_logo_url} onChange={handleChangeBrand} />
                   </Col>
                 </Row>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Col md={4}>
                     <Label>Primary Colour</Label>
                   </Col>
                   <Col md>
                     <InputColor initialValue={orgInfo.org_colour} onChange={handleChangePrimaryColour} placement="right" />
                   </Col>
                 </Row>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Col md={4}>
                     <Label>Background Colour</Label>
                   </Col>
                   <Col md>
                     <InputColor initialValue={orgInfo.org_background_colour} onChange={handleChangeBackgroundColour} placement="right" />
                   </Col>
                 </Row>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Col md={4}>
                     <Label>Metadata</Label>
                   </Col>
                   <Col md>
                     <Input name="org_metadata" value={orgInfo.org_metadata} onChange={handleChangeBrand} />
                   </Col>
                 </Row>
                 <Row className="row-cols-lg-auto g-3 align-items-center margin-2-top margin-2-bottom">
                   <Button color="primary" id="branding" onClick={handleSubmit}>
                     Update Branding
                   </Button>
                 </Row>
               </Col>
               <Col md>
                 <img src={orgInfo.org_logo_url} style={{ maxWidth: '100%' }} />
               </Col>
             </Row>
           </Container>
         </TabPane>
       </TabContent>

       <Modal isOpen={state.showResult} toggle={() => { setState({ ...state, showResult: false }) }}>
         <ModalHeader toggle={() => { setState({ ...state, showResult: false }) }}>Info</ModalHeader>
         <ModalBody>
           {state.apiMessage && (
             <span>{JSON.stringify(state.apiMessage.msg, null, 2)}</span>
           )}

            {state.connection && (
                <Button onClick={loginWithIdp}>Login with {state.connection.name}</Button>
            )}

           {state.error && (
             <span>{JSON.stringify(state.error, null, 2)}</span>
           )}
         </ModalBody>
         <ModalFooter>
           <Button color="primary" onClick={() => { setState({ ...state, showResult: false }) }}>
             OK
           </Button>
         </ModalFooter>
       </Modal>
     </>
   );
 };


export default withAuthenticationRequired(OrgAdmin, {
  onRedirecting: () => <Loading />,
});