import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Badge,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const apiOrigin = process.env.REACT_APP_API
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

      const [state, setState] = useState({
        org_name: 'TBC',
        organization: null,
        org_id: 'TBC',
        claims: null
      });

    const getOrgName = async () => {

      var orgId = user.org_id;
      console.log(orgId);
      try {
        const settings = {
                method: 'GET'
            };

            const response = await fetch(`${apiOrigin}/orgname?org_id=${orgId}`, settings);
            const responseData = await response.json();
            return responseData.org;

        } catch (error) {
            console.log("in error : " + error);
            return "";
        }
    };

        const getOrgId = async () => {

          var org_name = window.location.host.substring(0,window.location.host.indexOf("."));
          try {
            const settings = {
                    method: 'GET'
                };

                const response = await fetch(`${apiOrigin}/org?org_name=${org_name}`, settings);
                const responseData = await response.json();

                return responseData.org.id;

            } catch (error) {
                console.log("in error : " + error);
                return "";
            }
        };

  useEffect(() => {
    (async () => {
      try {

             if (!isAuthenticated && (!window.location.host.startsWith('addisonlee') || window.location.host.startsWith('addisonlee.addisonlee')) && !window.location.host.startsWith('localhost') && state.org_id === "TBC") {
                 const org = await getOrgId();
                 setState({
                    ...state,
                    org_id: org
                  });

            }

          if (isAuthenticated && state.claims == null) {

            const claims = await getIdTokenClaims();
               setState({
                  ...state,
                  claims: claims
                });

                console.log(claims);
          }



      } catch (err) {
          console.log(err);
      }
    })();
  }, []);

  const logoutWithRedirect = () =>
    logout({
      returnTo: 'https://addisonlee.westondemos.co.uk/',
      federated: true,
    });


  if (!isAuthenticated && (!window.location.host.startsWith('addisonlee')||window.location.host.startsWith('addisonlee.addisonlee')) && !window.location.host.startsWith('localhost') && state.org_id !== "" && state.org_id !== "TBC") {
        loginWithRedirect({organization: state.org_id})
  }

    if (isAuthenticated && (window.location.host.startsWith('addisonlee') && !window.location.host.startsWith('addisonlee.addisonlee')) && !window.location.host.startsWith('localhost') && user.org_id && state.claims && state.claims.orgdetails) {
          window.location.href='https://'+state.claims.orgdetails.name+'.addisonlee.westondemos.co.uk';
    }

  return (
    <div className="nav-container">
      <Navbar justify="true" color="light" light expand="md">
        <Container>
          <NavbarBrand className="logo" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>

              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/bookings"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Bookings
                  </NavLink>
                </NavItem>

              )}

              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/make-booking"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Make booking
                  </NavLink>
                </NavItem>

              )}

              {isAuthenticated && state.claims && state.claims.userroles && state.claims.userroles.includes('Admin') && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/admin"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Admin
                  </NavLink>
                </NavItem>
              )}

              {isAuthenticated && state.claims && state.claims.userroles && state.claims.userroles.includes('SuperAdmin') && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/super-admin"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Super Admin
                  </NavLink>
                </NavItem>
              )}
            </Nav>
                          {isAuthenticated && state.claims && state.claims.orgdetails && (
                            <NavItem>
                              <Badge color="success" pill>{state.claims.orgdetails.display_name}</Badge>
                            </NavItem>
                          )}
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}

              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
